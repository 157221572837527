import {
  BzDateFns,
  bzExpect,
  Guid,
  isNullish,
  IsoDateString,
  R,
} from '@breezy/shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { momentTimezone } from '@mobiscroll/react'
import { Radio, Select } from 'antd'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { useQuery, useSubscription } from 'urql'
import { z } from 'zod'
import DatePicker from '../../components/DatePicker/DatePicker'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import Switch from '../../elements/Switch/Switch'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { getAvatarShortStringForPerson } from '../../utils/TechnicianResource'
import {
  StateSetter,
  useQueryParamState,
  useQueryParamStateWithOptions,
} from '../../utils/react-utils'
import { AppointmentDetailsDrawer } from './AppointmentDetailsDrawer'
import { CommitScheduleChangesListener } from './CommitScheduleChangesListener/CommitScheduleChangesListener'
import { EditAppointmentDrawer } from './EditAppointmentDrawer'
import { SchedulePendingChangesWrapper } from './PendingChanges/SchedulePendingChangesContext'
import { Schedule } from './Schedule'
import {
  COMPANY_SCHEDULE_INFO_QUERY,
  INTERNAL_EVENTS_SUBSCRIPTION,
  SCHEDULE_ASSIGNMENTS_SUBSCRIPTION,
  UNASSIGNED_APPOINTMENTS_SUBSCRIPTION,
} from './Schedule.gql'
import {
  DEFAULT_SCHEDULE_MODE,
  DEFAULT_SCHEDULE_VIEW,
  SCHEDULE_MODE,
  SCHEDULE_VIEWS,
  ScheduleMode,
  ScheduleView,
  VALID_SCHEDULE_MODES,
  VALID_SCHEDULE_VIEWS,
} from './ScheduleContext'
import { MaintenanceOpportunityPane } from './ScheduleMapView/MaintenanceOpportunityPane'
import { ScheduleMapWrapper } from './ScheduleMapView/ScheduleMapContext'
import { ScheduleMapView } from './ScheduleMapView/ScheduleMapView'
import SchedulePlaygroundModeButtons from './SchedulePlaygroundModeButtons'
import { SchedulePlaygroundModeWrapper } from './SchedulePlaygroundModeContext'
import { ScheduleWeatherWidget } from './ScheduleWeatherWidget'
import { ToSchedulePane } from './ToSchedulePane/ToSchedulePane'
import {
  FullScheduleAppointment,
  getStartDateForView,
  SchedulePageContext,
  SchedulePopoverContext,
  TechnicianResource,
} from './scheduleUtils'
import { PersistedDismissedRunningLateAlertsContextProvider } from './usePersistedDismissedRunningLateAlerts'

momentTimezone.moment = moment

const VIEW_PICKER_OPTIONS = R.keys(SCHEDULE_VIEWS).map(key => ({
  label: SCHEDULE_VIEWS[key].label,
  value: key,
}))

const MODE_PICKER_OPTIONS = R.keys(SCHEDULE_MODE).map(key => ({
  label: SCHEDULE_MODE[key].label,
  value: key,
  icon: SCHEDULE_MODE[key].icon,
}))

export type ScheduleModeLabel = (typeof SCHEDULE_MODE)[ScheduleMode]['label']

export const ScheduleV2Page = React.memo(() => {
  const tzId = useExpectedCompanyTimeZoneId()
  const companyGuid = useExpectedCompanyGuid()

  const [scheduleViewPersistedValue, setScheduleViewPersistedValue] =
    useLocalStorage<(typeof VALID_SCHEDULE_VIEWS)[number]>('scheduleView')

  const [scheduleView, setScheduleViewRaw] = useQueryParamStateWithOptions(
    'view',
    !isNullish(scheduleViewPersistedValue)
      ? scheduleViewPersistedValue
      : DEFAULT_SCHEDULE_VIEW,
    VALID_SCHEDULE_VIEWS,
  )

  useEffect(() => {
    setScheduleViewPersistedValue(scheduleView)
  }, [scheduleView, setScheduleViewPersistedValue])

  const [scheduleModePersistedValue, setScheduleModePersistedValue] =
    useLocalStorage<(typeof VALID_SCHEDULE_MODES)[number]>('scheduleMode')

  const [scheduleMode, setScheduleModeRaw] = useQueryParamStateWithOptions(
    'mode',
    !isNullish(scheduleModePersistedValue)
      ? scheduleModePersistedValue
      : DEFAULT_SCHEDULE_MODE,
    VALID_SCHEDULE_MODES,
  )

  useEffect(() => {
    setScheduleModePersistedValue(scheduleMode)
  }, [scheduleMode, setScheduleModePersistedValue])

  const defaultStartDate = useMemo(
    () => getStartDateForView(scheduleView, tzId),
    [scheduleView, tzId],
  )
  const selectedDateOptions = useMemo(
    () => ({
      encode: (date: IsoDateString) => date,
      decode: (date: string) => {
        if (z.string().datetime().safeParse(date).success) {
          return date as IsoDateString
        }
        return defaultStartDate
      },
    }),
    [defaultStartDate],
  )

  const [selectedDate, setSelectedDateRaw] = useQueryParamState(
    'date',
    defaultStartDate,
    selectedDateOptions,
  )

  const setSelectedDate = useCallback<StateSetter<IsoDateString>>(
    date => {
      setSelectedDateRaw(oldDate => {
        const ourDate = typeof date === 'function' ? date(oldDate) : date
        // Whatever our new date is, we have to adjust it based on the view (either to start of day or start of week)
        return getStartDateForView(scheduleView, tzId, ourDate)
      })
    },
    [scheduleView, setSelectedDateRaw, tzId],
  )

  const setScheduleView = useCallback(
    (view: ScheduleView) => {
      // If we change views from a day view to a week view, we need to use the start of week date, which this will
      // figure out.
      setSelectedDateRaw(
        getStartDateForView(
          view,
          tzId,
          // If they are going from the one-week view to the day or dispatch, the selected date will be the start of the
          // week, which is not today (the expectation). So in that case, use the default (which is today).
          scheduleView === 'ONE_WEEK' && view !== 'ONE_WEEK'
            ? undefined
            : selectedDate,
        ),
      )
      setScheduleModeRaw('CALENDAR')
      setScheduleViewRaw(view)
    },
    [
      setSelectedDateRaw,
      tzId,
      scheduleView,
      selectedDate,
      setScheduleModeRaw,
      setScheduleViewRaw,
    ],
  )

  const setScheduleMode = useCallback(
    (scheduleMode: ScheduleMode) => {
      if (scheduleMode === 'MAP') {
        // If we switch to map view, we default it to today (leaving "selectedDate" as `undefined` here).
        setSelectedDateRaw(getStartDateForView('DAY', tzId))
        setScheduleViewRaw('DAY')
      }
      setScheduleModeRaw(scheduleMode)
    },
    [setScheduleModeRaw, setScheduleViewRaw, setSelectedDateRaw, tzId],
  )

  const endDate = useMemo(
    () =>
      BzDateFns.withTimeZone(selectedDate, tzId, start => {
        let days = 1
        if (scheduleView === 'ONE_WEEK') {
          days = 7
        }
        const lastDay = BzDateFns.addDays(start, days - 1)
        return BzDateFns.endOfDay(lastDay)
      }),
    [scheduleView, selectedDate, tzId],
  )

  const [{ data: companyScheduleInfo, fetching: companyScheduleInfoFetching }] =
    useQuery({
      query: COMPANY_SCHEDULE_INFO_QUERY,
      variables: { companyGuid },
    })

  const techList = useMemo<TechnicianResource[]>(() => {
    if (!companyScheduleInfo) {
      return []
    }

    // We have a list of user guids that represents the preferred order of the techs. There is no guarantee that the
    // list matches the actual techs. There could be tech guids in the list that are no longer users, and there can be
    // new users that have never been put in order. First we'll create a map with all the users. Then we'll iterate
    // through the ordered guids and add the corresponding users to the list. If there is no user for that guid (they
    // were deleted since the last time the order was changed) then we skip them because they aren't in the map. Once we
    // see a user, we flip the value in the map to "false", signifying that the user at that guid was added to the list
    // already. After that, we iterate through the items in the map. If the item is false, that means they are already
    // in the list. Otherwise, they weren't in the ordered guid list and we just add them to the end.
    const map: Record<string, TechnicianResource | false> = {}

    for (const user of companyScheduleInfo.companyUsers) {
      map[user.userGuid] = {
        ...user,
        ...user.user,
        id: user.userGuid,
        name: `${user.user.firstName} ${user.user.lastName}`,
        roles: user.user.userRoles,
        avatarShortString: getAvatarShortStringForPerson(user.user),
      }
    }

    const techList: TechnicianResource[] = []

    const techGuidsInOrder =
      companyScheduleInfo.companyConfigByPk?.scheduleTechOrder ?? []

    for (const userGuid of techGuidsInOrder) {
      const tech = map[userGuid]
      if (tech) {
        techList.push(tech)
        map[userGuid] = false
      }
    }

    for (const tech of R.values(map)) {
      if (tech) {
        techList.push(tech)
      }
    }

    return techList
  }, [companyScheduleInfo])

  const validTechList = useMemo(
    () => techList.filter(tech => !tech.deactivatedAt),
    [techList],
  )

  const [pause, setPause] = useState(false)

  const [assignmentsSubscription] = useSubscription({
    query: SCHEDULE_ASSIGNMENTS_SUBSCRIPTION,
    variables: { startDate: selectedDate, endDate },
    pause,
  })

  const [unassignedAppointmentsSubscription] = useSubscription({
    query: UNASSIGNED_APPOINTMENTS_SUBSCRIPTION,
    variables: { startDate: selectedDate, endDate },
    pause,
  })

  const [internalEventsSubscription] = useSubscription({
    query: INTERNAL_EVENTS_SUBSCRIPTION,
    variables: { startDate: selectedDate, endDate },
    pause,
  })

  const [forcePopoverHidden, setForcePopoverHidden] = useState(false)

  const [selectedAppointmentGuid, setSelectedAppointmentGuidRaw] =
    useQueryParamState('appointment', '')

  const setSelectedAppointmentGuid = useCallback<
    typeof setSelectedAppointmentGuidRaw
  >(
    (...args) => {
      setForcePopoverHidden(true)
      setSelectedAppointmentGuidRaw(...args)
    },
    [setSelectedAppointmentGuidRaw],
  )

  const [editingAppointmentGuid, setEditingAppointmentGuidRaw] =
    useQueryParamState('editingAppointment', '')

  const setEditingAppointmentGuid = useCallback<
    typeof setEditingAppointmentGuidRaw
  >(
    (...args) => {
      setForcePopoverHidden(true)
      setEditingAppointmentGuidRaw(...args)
    },
    [setEditingAppointmentGuidRaw],
  )

  useEffect(() => {
    if (!selectedAppointmentGuid && !editingAppointmentGuid) {
      setForcePopoverHidden(false)
    }
  }, [editingAppointmentGuid, selectedAppointmentGuid])

  const scheduleAssignments = useMemo(
    () =>
      // Note: though I COULD put this in the query, because of the nature of the schema, it increases the query time by
      // an order of magnitude.
      (assignmentsSubscription.data?.jobAppointmentAssignments ?? []).filter(
        assignment => !assignment.appointment.cancellationStatus?.canceled,
      ),
    [assignmentsSubscription.data?.jobAppointmentAssignments],
  )
  const unassignedAppointments = useMemo(
    () =>
      // Note: though I COULD put this in the query, because of the nature of the schema, it increases the query time by
      // an order of magnitude.
      (unassignedAppointmentsSubscription.data?.appointments ?? []).filter(
        appointment => !appointment.cancellationStatus?.canceled,
      ),
    [unassignedAppointmentsSubscription.data?.appointments],
  )

  const appointmentMap = useMemo(() => {
    const appointmentMap: Record<string, FullScheduleAppointment | undefined> =
      {}
    for (const assignment of scheduleAssignments) {
      const appointment = appointmentMap[assignment.appointmentGuid] ?? {
        ...assignment.appointment,
        assignments: [],
      }

      // I know for a variety of reasons that `appt.assignments` is defined. For one, I haven't done the unassigned
      // loop yet. Second, since these are assignments, the appointments in question are by definition not unassigned.
      appointment.assignments!.push(assignment)

      appointmentMap[assignment.appointmentGuid] = appointment
    }
    for (const appointment of unassignedAppointments) {
      appointmentMap[appointment.appointmentGuid] = appointment
    }
    return appointmentMap
  }, [scheduleAssignments, unassignedAppointments])

  const allAppointments = useMemo(
    () => R.values(appointmentMap) as FullScheduleAppointment[],
    [appointmentMap],
  )

  const selectedAppointment = appointmentMap[selectedAppointmentGuid]

  // We can only open the appointment details page for appointments we have, and we only have the appointments for the
  // selected day. If you navigate to a future day, open appointment details, then refresh, it will have the query param
  // and no sidebar. Then when you navigate to the date you were on, the sidebar immediately opens.
  useEffect(() => {
    if (selectedAppointmentGuid && !selectedAppointment) {
      setSelectedAppointmentGuid('')
    }
  }, [selectedAppointment, selectedAppointmentGuid, setSelectedAppointmentGuid])

  const editingAppointment = appointmentMap[editingAppointmentGuid]

  const internalEvents = useMemo(
    () => internalEventsSubscription.data?.technicianCapacityBlocks ?? [],
    [internalEventsSubscription.data?.technicianCapacityBlocks],
  )

  const [selectedTechGuidsPersistedValue, setSelectedTechGuidsPersistedValue] =
    useLocalStorage<Guid[]>('selectedTechGuids')

  const [selectedTechGuids, setSelectedTechGuids] = useState<Guid[]>(
    !isNullish(selectedTechGuidsPersistedValue)
      ? selectedTechGuidsPersistedValue
      : [],
  )

  useEffect(() => {
    setSelectedTechGuidsPersistedValue(selectedTechGuids)
  }, [selectedTechGuids, setSelectedTechGuidsPersistedValue])

  const noSubscriptionsFetching = !(
    internalEventsSubscription.fetching ||
    assignmentsSubscription.fetching ||
    unassignedAppointmentsSubscription.fetching
  )

  const isLoading =
    companyScheduleInfoFetching ||
    !assignmentsSubscription.data ||
    !internalEventsSubscription.data
  return (
    <Page requiresCompanyUser>
      <div className="schedule-v2 flex h-full min-h-[800px] flex-row space-x-2">
        <SchedulePageContext.Provider
          value={{
            scheduleMode,
            scheduleView,
            setScheduleView,
            selectedDate,
            setSelectedDate,
            setSelectedAppointmentGuid,
            setEditingAppointmentGuid,
            editingAppointmentGuid,
            selectedTechGuids,
          }}
        >
          <PersistedDismissedRunningLateAlertsContextProvider>
            <SchedulePopoverContext.Provider
              value={{ forcePopoverHidden, setForcePopoverHidden }}
            >
              <SchedulePendingChangesWrapper
                setSubscriptionsPaused={setPause}
                scheduleAssignments={scheduleAssignments}
                unassignedAppointments={unassignedAppointments}
                internalEvents={internalEvents}
                appointmentMap={appointmentMap}
                allAppointments={allAppointments}
                noSubscriptionsFetching={noSubscriptionsFetching}
              >
                <SchedulePlaygroundModeWrapper>
                  <CommitScheduleChangesListener />
                  <ScheduleMapWrapper>
                    {scheduleMode === 'MAP' && <MaintenanceOpportunityPane />}
                    <div className="schedule-panel relative flex min-w-[1000px] flex-1 flex-col p-4 pt-0">
                      <div className="flex w-full flex-row flex-wrap items-center justify-between py-3">
                        <div
                          className="flex flex-row items-center space-x-3"
                          data-testid="schedule-view-controls"
                        >
                          <Radio.Group
                            onChange={e => setScheduleView(e.target.value)}
                            value={scheduleView}
                            optionType="button"
                            buttonStyle="solid"
                          >
                            {VIEW_PICKER_OPTIONS.map(({ label, value }) => (
                              <Radio.Button key={value} value={value}>
                                <div
                                  key={value}
                                  data-dd-action-name={`BZ Schedule View - ${label}`}
                                >
                                  {label}
                                </div>
                              </Radio.Button>
                            ))}
                          </Radio.Group>

                          <div className="my-2 hidden h-6 w-px bg-bz-gray-500 xl:block" />

                          <Radio.Group
                            onChange={e => setScheduleMode(e.target.value)}
                            value={scheduleMode}
                            optionType="button"
                            buttonStyle="solid"
                          >
                            {MODE_PICKER_OPTIONS.map(
                              ({ label, value, icon }) => (
                                <Radio.Button key={value} value={value}>
                                  <div
                                    key={value}
                                    data-dd-action-name={`BZ Schedule Mode - ${label}`}
                                  >
                                    <FontAwesomeIcon
                                      className="mr-2"
                                      icon={icon}
                                    />
                                    {label}
                                  </div>
                                </Radio.Button>
                              ),
                            )}
                          </Radio.Group>
                          <div className="my-2 hidden h-6 w-px bg-bz-gray-500 xl:block" />
                          <Select
                            className="tech-filter-select min-w-[175px] text-base"
                            mode="multiple"
                            allowClear
                            showSearch={false}
                            placeholder="All Team Members"
                            maxTagCount={0}
                            maxTagPlaceholder={`Technicians (${selectedTechGuids.length})`}
                            value={selectedTechGuids}
                            onChange={setSelectedTechGuids}
                            popupMatchSelectWidth={false}
                            options={validTechList.map(
                              ({ firstName, lastName, userGuid }) => ({
                                value: userGuid,
                                label: `${firstName} ${lastName} `,
                              }),
                            )}
                            filterOption={(input, option) => {
                              if (!option || !option.label) {
                                return false
                              }

                              return option.label
                                .toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }}
                          />
                        </div>
                        {scheduleMode === 'MAP' && (
                          <div className="row flex-between flex flex-1 items-center">
                            <ScheduleWeatherWidget />
                            <div className="row flex flex-1 items-center justify-end">
                              <DatePicker
                                id="schedule-date-picker"
                                allowClear={false}
                                value={BzDateFns.parseISO(selectedDate, tzId)}
                                onChange={date => {
                                  setSelectedDate(
                                    BzDateFns.formatISO(bzExpect(date), tzId),
                                  )
                                }}
                                format="MMM Do, YYYY"
                              />
                            </div>
                          </div>
                        )}
                        {scheduleMode === 'CALENDAR' && (
                          <div className="flex">
                            <SchedulePlaygroundModeButtons />
                          </div>
                        )}
                      </div>
                      {isLoading || !companyScheduleInfo ? (
                        <div className="flex h-full w-full items-center justify-center">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <Switch value={scheduleMode}>
                          {{
                            CALENDAR: () => (
                              <div className="min-h-0 flex-1">
                                <Schedule
                                  scheduleInfo={companyScheduleInfo}
                                  techList={techList}
                                />
                              </div>
                            ),
                            MAP: () => <ScheduleMapView techList={techList} />,
                            default: () => <LoadingSpinner />,
                          }}
                        </Switch>
                      )}
                    </div>
                  </ScheduleMapWrapper>
                  {scheduleMode === 'CALENDAR' && <ToSchedulePane />}

                  <AppointmentDetailsDrawer
                    appointment={selectedAppointment}
                    techList={techList}
                  />

                  <EditAppointmentDrawer appointment={editingAppointment} />
                </SchedulePlaygroundModeWrapper>
              </SchedulePendingChangesWrapper>
            </SchedulePopoverContext.Provider>
          </PersistedDismissedRunningLateAlertsContextProvider>
        </SchedulePageContext.Provider>
      </div>
    </Page>
  )
})
